<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    v-if="this.$root.mobile"
    :fullscreen="this.$root.mobile"
    transition="dialog-bottom-transition"
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card>
      <!-- <v-card-title>
        <span class="headline">Edit History</span>
      </v-card-title> -->
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("MF.vital.new") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-form ref="mdHistoryForm" v-model="valid">
            <v-autocomplete
              v-model="mdVitalsForm.vital_type"
              clearable
              outlined
              :items="itemTypes"
              :loading="itemTypesLoaded"
              :filter="typeFilter"
              :label="$t('MF.vital.form.type')"
              item-text="name"
              item-value="name"
              return-object
              :rules="[v => !!v || $t('MF.vital.validation.type')]"
            >
              <template v-slot:selection="data">
                {{ data.item.name }}
              </template>
              <template v-slot:item="data">
                {{ data.item.name }}
              </template>
            </v-autocomplete>

            <v-text-field
              type="number"
              outlined
              :label="$t('MF.vital.form.reading')"
              :suffix="getSuffix()"
              v-model="mdVitalsForm.reading"
              :rules="[v => !!v || $t('MF.vital.validation.reading')]"
            ></v-text-field>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
          {{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog" persistent max-width="700px" v-else>
    <v-card>
      <v-card-title>
        <span class="headline">{{$t('MF.vital.update')}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="mdVitalsForm" v-model="valid">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <v-autocomplete
                  v-model="mdVitalsForm.vital_type"
                  clearable
                  outlined
                  :items="itemTypes"
                  :loading="itemTypesLoaded"
                  :filter="typeFilter"
                  :label="$t('MF.vital.form.type')"
                  item-text="name"
                  item-value="name"
                  return-object
                  :rules="[v => !!v || $t('MF.vital.validation.type')]"
                >
                  <template v-slot:selection="data">
                    {{ data.item.name }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.name }}
                  </template>
                </v-autocomplete>
              </div>
              <!-- reading -->
              <div class="col-lg-6 col-md-6 col-sm-6">
                <v-text-field
              type="number"
              outlined
              :label="$t('MF.vital.form.reading')"
              :suffix="getSuffix()"
              v-model="mdVitalsForm.reading"
              :rules="[v => !!v || $t('MF.vital.validation.reading')]"
            ></v-text-field>
                
              </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
          {{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  props: ["dialog", "record"],
  data() {
    return {
      error: false,
      submitted: false,
      errorMsg: "",
      itemTypes: [],
      itemTypesLoaded: true,
      selectedType: "",
      menu2: false,
      valid: false,
      askStarted: false,
      mdVitalsForm: {
        reading: "",
        vital_type: ""
      },
      
    };
  },
  methods: {
    getTranslation(d) {
      //console.log(d);
      if (d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
    async submit() {
      try {
        if (this.$refs.mdVitalsForm.validate()) {
          this.submitted = true;
          const payload = {
            reading: this.mdVitalsForm.reading,
            vital_type: this.mdVitalsForm.vital_type.id,
            method: "1"
          };

          await ApiService.update("items/md_vitals", this.record.id, payload);
          this.submitted = false;
          this.$refs.mdVitalsForm.reset();
          this.$emit("formSubmitted", this.$t('MF.vital.form.success'));
        }
      } catch (error) {
        if (error.message == "") {
          this.error = error;
        } else {
          this.error = this.$t('MF.vital.form.error');
        }
        console.log(error);
      }
    },
    async getItemTypes() {
      try {
        const { data } = await ApiService.get(
          "items",
          "vital_types?fields=id,name,suffix,translation.*"
        );
        this.itemTypes = data.data;
        this.itemTypesLoaded = false;
      } catch (error) {
        console.log(error);
        this.error = error;
      }
    },
    getSuffix() {
      if (this.mdVitalsForm.vital_type.suffix) {
        return this.mdVitalsForm.vital_type.suffix;
      } else {
        return "";
      }
    },
    typeFilter(item, queryText) {
      return item.type.toLowerCase().includes(queryText.toLowerCase());
    },
    close() {
      this.$emit("closeDialog");
    }
  },
  mounted() {
    this.getItemTypes();

    this.record &&
      Object.assign(this.mdVitalsForm, {
        reading: this.record.reading,
        vital_type: this.record.vital_type
      });
  }
};
</script>
